<template>
    <header class="page-title">
        <h1><i class="bi bi-list-ul"></i> 案件マスタ</h1>
        <router-link class="btn btn-outline-secondary" :to="{name: 'MasterProject'}"><i class="bi bi-reply"></i> 一覧に戻る</router-link>
    </header>

    <section class="section">
        <form @submit.prevent="save">
            <div class="row mb-3">
                <div class="col-6">
                    <label class="form-label">案件名 (略称)</label>
                    <form-input v-model="project.project_name" required :disabled="loading"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">正式名称</label>
                    <form-input v-model="project.official_name" :disabled="loading"></form-input>
                </div>
                <div class="col-6">
                    <label class="form-label">タイプ</label>
                    <div class="form-readonly">{{ project.project_type_label }}</div>
                </div>
                <div class="col-6">
                    <label class="form-label">有効期限</label>
                    <div class="input-group">
                        <form-input-month  v-model="project.available_from" required :disabled="loading"></form-input-month>
                        <span class="input-group-text">〜</span>
                        <form-input-month  v-model="project.available_to" :disabled="loading"></form-input-month>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="project.project_type !== ProjectType.BACK_OFFICE">
                <div class="col-6">
                    <label class="form-label">顧客</label>
                    <div class="input-group">
                        <form-select-object-search v-model="project.customer" :options="customers" option_value="customer_id" option_label="customer_name" required :disabled="customers.length === 0" @selected="project.billing_customer = project.customer"></form-select-object-search>
                        <button type="button" class="btn btn-outline-secondary" @click="is_adding_customer = true;"><i class="bi bi-plus"></i></button>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">請求先</label>
                    <div class="input-group">
                        <form-select-object-search v-model="project.billing_customer" :options="customers" option_value="customer_id" option_label="customer_name" required :disabled="customers.length === 0"></form-select-object-search>
                        <button type="button" class="btn btn-outline-secondary" @click="is_adding_billing_customer = true;"><i class="bi bi-plus"></i></button>
                    </div>
                </div>
                <div class="col-6">
                    <label class="form-label">担当部門</label>
                    <form-select-object v-model="project.department" :options="departments" option_value="department_id" option_label="department_name" empty_option="-- 選択 --" :disabled="departments.length === 0"></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">担当者</label>
                    <form-select-object v-model="project.user" :options="users" option_value="user_id" option_label="user_name" empty_option="-- 選択 --" :disabled="users.length === 0"></form-select-object>
                </div>
            </div>
            <div class="row mb-3" v-if="project.project_type !== ProjectType.BACK_OFFICE">
                <div class="col-6">
                    <label class="form-label">分類1</label>
                    <form-select-object v-model="project.category_1" :options="categories_1" option_value="category_id" option_label="category_name" empty_option="-- 選択 --" :disabled="categories_1.length === 0"></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">分類2</label>
                    <form-select-object v-model="project.category_2" :options="categories_2" option_value="category_id" option_label="category_name" empty_option="-- 選択 --" :disabled="categories_2.length === 0"></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">分類3</label>
                    <form-select-object v-model="project.category_3" :options="categories_3" option_value="category_id" option_label="category_name" empty_option="-- 選択 --" :disabled="categories_3.length === 0"></form-select-object>
                </div>
                <div class="col-6">
                    <label class="form-label">分類4</label>
                    <form-select-object v-model="project.category_4" :options="categories_4" option_value="category_id" option_label="category_name" empty_option="-- 選択 --" :disabled="categories_4.length === 0"></form-select-object>
                </div>
            </div>
            <div class="row mb-3" v-if="project.project_type === ProjectType.CONTINUOUS">
                <div class="col-6">
                    <label class="form-label">標準月額売上</label>
                    <div class="input-group">
                        <form-input-number v-model="project.monthly_sales" required></form-input-number>
                        <span class="input-group-text">円</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3" v-if="project.project_type === ProjectType.TIME_AND_MATERIAL">
                <div class="col-6">
                    <label class="form-label">請求時間単価</label>
                    <div class="input-group">
                        <form-input-number v-model="project.labor_unit_sales" required></form-input-number>
                        <span class="input-group-text">円</span>
                    </div>
                </div>
            </div>

            <template v-if="!loading">
                <template v-if="project.project_type === ProjectType.ONE_OFF">
                    <h4 class="text-secondary mt-5">フェーズ</h4>
                    <div class="row border-bottom col-12">
                        <div class="col-12"><label class="form-label">フェーズ名</label></div>
                        <div class="col-8"><label class="form-label">受注金額</label></div>
                        <div class="col-4"></div>
                    </div>
                    <div class="row py-3 border-bottom col-12" v-for="phase,index of project.phases" :key="index">
                        <div class="col-12">
                            <form-input v-model="phase.phase_name" required></form-input>
                        </div>
                        <div class="col-8">
                            <form-input-number v-model="phase.sales_price" required></form-input-number>
                        </div>
                        <div class="col-4">
                            <button type="button" class="btn btn-outline-danger" @click="remove_phase(index)"><i class="bi bi-trash"></i></button>
                        </div>
                    </div>
                    <div class="row py-3 col-12">
                        <div class="col-4 offset-20">
                            <button type="button" class="btn btn-outline-primary" @click="add_phase()"><i class="bi bi-plus"></i> 追加</button>
                        </div>
                    </div>
                </template>

                <template v-else>
                    <h4 class="text-secondary mt-5">作業カテゴリ</h4>
                    <div class="row border-bottom col-12">
                        <div class="col-20"><label class="form-label">カテゴリ名</label></div>
                        <div class="col-4"></div>
                    </div>
                    <div class="row py-3 border-bottom col-12" v-for="work_category,index of project.work_categories" :key="index">
                        <div class="col-20">
                            <form-input v-model="work_category.category_name" required></form-input>
                        </div>
                        <div class="col-4">
                            <button type="button" class="btn btn-outline-danger" @click="remove_work_category(index)"><i class="bi bi-trash"></i></button>
                        </div>
                    </div>
                    <div class="row py-3 col-12">
                        <div class="col-4 offset-20">
                            <button type="button" class="btn btn-outline-primary" @click="add_work_category()"><i class="bi bi-plus"></i> 追加</button>
                        </div>
                    </div>
                </template>
            </template>

            <div class="mt-5">
                <button class="btn btn-info btn-lg" type="submit" :disabled="loading">保存</button>
            </div>

            <div class="mt-5 text-end">
                <button class="btn btn-outline-danger" type="button" @click="this.$refs.confirmRemove.show();" :disabled="loading">削除</button>
            </div>
        </form>
    </section>

    <transition name="fade">
        <div v-if="is_adding_customer || is_adding_billing_customer">
            <div class="modal-backdrop show"></div>
            <div class="modal" tabindex="-1" style="display:block">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <i class="bi bi-exclamation-diamond-fill fs-5 text-warning"></i>&nbsp;
                            <h5 class="modal-title">新規顧客を追加</h5>
                            <button type="button" class="btn-close" @click="is_adding_customer = false; is_adding_billing_customer = false;"></button>
                        </div>
                        <div class="modal-body">
                            <label class="form-label">顧客名</label>
                            <form-input required v-model="adding_customer.customer_name" />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="is_adding_customer = false; is_adding_billing_customer = false;">キャンセル</button>
                            <button type="button" class="btn btn-primary" @click="addCustomer">追加</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>本当に削除してよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import Project from "@/models/entities/project";
import Customer from "@/models/entities/customer";
import Category from "@/models/entities/category";
import Phase from "@/models/entities/phase";
import WorkCategory from "@/models/entities/work-category";
import Department from "@/models/entities/department";
import User from "@/models/entities/user";
import ProjectType from "@/models/enums/project-type";

export default {
    name: 'MasterProjectEdit',
    components: {
        FormInput,
        FormInputNumber,
        FormInputMonth,
        FormSelectObject,
        FormSelectObjectSearch,
        ConfirmDialog
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            project_id: parseInt(this.$route.params.project),
            project: new Project(),
            customers: [],
            categories_1: [],
            categories_2: [],
            categories_3: [],
            categories_4: [],
            users: [],
            departments: [],
            ProjectType,
            is_adding_customer: false,
            is_adding_billing_customer: false,
            adding_customer: new Customer(),
        }
    },
    mounted() {
        this.$http.get(`/master/project/${this.project_id}`)
        .then((response) => {
            this.project = new Project(response.data);
            this.loading = false;
        })

        this.$http.get('/list/customer')
        .then((response) => {
            this.customers = response.data.map((row) => {return new Customer(row)});
        })

        this.$http.get('/list/taxonomy/1/category')
        .then((response) => {
            this.categories_1 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/2/category')
        .then((response) => {
            this.categories_2 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/3/category')
        .then((response) => {
            this.categories_3 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/taxonomy/4/category')
        .then((response) => {
            this.categories_4 = response.data.map((row) => {return new Category(row)});
        })

        this.$http.get('/list/user')
        .then((response) => {
            this.users = response.data.map((row) => {return new User(row)});
        })

        this.$http.get('/list/department')
        .then((response) => {
            this.departments = response.data.map((row) => {return new Department(row)});
        })
    },
    methods: {
        save() {
            if (this.project.project_type === ProjectType.ONE_OFF) {
                this.project.work_categories.splice(0);
            } else {
                this.project.phases.splice(0);
            }

            this.startScreenLoading();
            this.$http.put(`/master/project/${this.project_id}`, this.project)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        remove() {
            this.startScreenLoading();
            this.$http.delete(`/master/project/${this.project_id}`)
            .then(() => {
                this.showMessage('削除しました');
                this.$router.push({name: 'MasterProject'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        add_phase() {
            this.project.phases.push(new Phase());
        },
        remove_phase(index) {
            this.project.phases.splice(index, 1);
        },
        add_work_category() {
            this.project.work_categories.push(new WorkCategory());
        },
        remove_work_category(index) {
            this.project.work_categories.splice(index, 1);
        },
        addCustomer() {
            if (this.$helper.isBlank(this.adding_customer.customer_name)) {
                this.showErrorMessage('顧客名を入力してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/customer', this.adding_customer)
            .then(response => {
                // 登録されたデータをリストと値に追加
                this.customers.push(new Customer(response.data));
                if (this.is_adding_customer) {
                    this.project.customer = new Customer(response.data);
                    this.project.billing_customer = new Customer(response.data);
                } else if (this.is_adding_billing_customer) {
                    this.project.billing_customer = new Customer(response.data);
                }
                // 追加行を初期化
                this.adding_customer = new Customer();
                // モーダルを閉じる
                this.is_adding_customer = false;
                this.is_adding_billing_customer = false;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
}
</script>

<style scoped>
</style>
